import { gql, useLazyQuery } from "@apollo/client";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { CORE_FACEBOOK_AD_ACCOUNT_FIELDS } from "../../fragments";
import { doesApolloErrorIncludeErrorOfType } from "../../helpers";
import { isFacebookLoggedIn, useFacebook } from "../../lib/facebook";

import AudienceFacebookExportModal from "./AudienceFacebookExportModal";

const FETCH_AD_ACCOUNTS = gql`
  ${CORE_FACEBOOK_AD_ACCOUNT_FIELDS}
  query {
    user {
      id
      facebookAdAccounts {
        ...CoreFacebookAdAccountFields
      }
    }
  }
`;

const AudienceFacebookExportButton = ({ audience }) => {
  const { user, didFacebookLoginFail } = useAppState();
  const dispatch = useAppStateDispatch();
  const [fetchAdAccounts] = useLazyQuery(FETCH_AD_ACCOUNTS, { fetchPolicy: "no-cache" });
  const { facebookInit, facebookLogin } = useFacebook();

  const [adAccounts, setAdAccounts] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetchAdAccountsLoading, setIsFetchAdAccountsLoading] = useState(false);

  useEffect(facebookInit, [facebookInit]);

  // If the facebook login attempt failed, we set `didFacebookLoginFail` in the app state
  // (see `facebookLogin`). When that happens, we want to handle it here by closing the modal
  // so it doesn't show infinite loading dots. We also reset that piece of app state since
  // we've handled it here and the flow is over.
  useEffect(() => {
    if (didFacebookLoginFail) {
      dispatch({ type: "did-facebook-login-fail-update", didFacebookLoginFail: undefined });
      setIsModalOpen(false);
    }
  }, [didFacebookLoginFail, dispatch]);

  if (!user.isStaff) {
    return null;
  }

  const processFetchedAdAccountsFn = responseKey => data => {
    if (!isFacebookLoggedIn()) {
      toast("You must log in to Facebook to proceed.", { type: "error" });
    }
    setAdAccounts(data[responseKey].facebookAdAccounts);
    setIsFetchAdAccountsLoading(false);
  };

  const onClick = () => {
    setIsModalOpen(true);
    // If there's already at least one FB audience export of this audience, let's tell the user
    // that before we bother to do FB auth.
    if (!audience.facebookCustomAudienceExports.find(fcae => fcae.status === "ACTIVE")) {
      fetchAndProcessAdAccounts();
    }
  };

  const fetchAndProcessAdAccounts = () => {
    setIsFetchAdAccountsLoading(true);
    if (isFacebookLoggedIn()) {
      fetchAdAccounts({
        onCompleted: processFetchedAdAccountsFn("user"),
        onError: err => {
          // If this happens, it means that 1. the user is logged into Facebook on the frontend but
          // 2. We don't have a credential on the backend.
          // In this case we need to login again, since that will get the credential and save it.
          if (doesApolloErrorIncludeErrorOfType(err, "CredentialDoesNotExist")) {
            facebookLogin(processFetchedAdAccountsFn("saveUserCredential"));
          }
        },
      });
    } else {
      facebookLogin(processFetchedAdAccountsFn("saveUserCredential"));
    }
  };

  return (
    <>
      <Button onClick={onClick} startIcon={<FacebookIcon />}>
        Export to Facebook
      </Button>
      <AudienceFacebookExportModal
        adAccounts={adAccounts}
        audience={audience}
        isFetchAdAccountsLoading={isFetchAdAccountsLoading}
        isOpen={isModalOpen}
        refetchAdAccounts={fetchAndProcessAdAccounts}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};

export default AudienceFacebookExportButton;
