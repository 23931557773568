import { gql, useMutation } from "@apollo/client";
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@mui/material";
import dayjs from "dayjs";
import { isEqual, isEqualWith } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBlocker, useNavigate, Link, useParams, useLocation } from "react-router-dom";

import { CORE_AUDIENCE_FIELDS } from "../../fragments";
import { objectWithSortedListValues, userFriendlyNumber } from "../../helpers";
import useFormErrors from "../../hooks/useFormErrors";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import useGetAudienceFromParam from "../../hooks/useGetAudienceFromParam";
import useGetCurrentAudiences from "../../hooks/useGetCurrentAudiences";
import useGetDefaultFilters from "../../hooks/useGetDefaultFilters";
import useTransformFiltersForBackendFn from "../../hooks/useTransformFiltersForBackendFn";
import useTransformedFiltersFromBackend from "../../hooks/useTransformedFiltersFromBackend";
import FixedToBottomGrid from "../shared/FixedToBottomGrid";
import InfoBox from "../shared/InfoBox";
import LoadingExtended from "../shared/LoadingExtended";
import TextLabel from "../shared/TextLabel";
import TwoColumnLayoutWithPreview from "../shared/TwoColumnLayoutWithPreview";

import AudienceFilters from "./AudienceFilters";
import FiltersSummaries from "./FiltersSummaries";
import PreviewAudience from "./PreviewAudience";
import PromoteAudience from "./PromoteAudience";
import SelectAudience from "./SelectAudience";

const PREVIEW_LOADING_STEPS = ["Loading voter file", "Applying filters", "Aggregating data"];

// Note that none of these mutations do their operations async so this makes it pretty simple.
const NARROW_AUDIENCE = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($input: AudienceWithFiltersFromAudienceInput!) {
    narrowAudience(input: $input) {
      ...CoreAudienceFields
    }
  }
`;

const NARROW_FROM_NATIONAL = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($input: AudienceWithFiltersFromNationalInput!) {
    narrowFromNational(input: $input) {
      ...CoreAudienceFields
    }
  }
`;

const COPY_AND_EDIT_AUDIENCE_FILTERS = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($input: AudienceWithFiltersFromAudienceInput!) {
    copyAndEditAudienceFilters(input: $input) {
      ...CoreAudienceFields
    }
  }
`;

const customCompare = (valA, valB) => {
  if (dayjs.isDayjs(valA) && dayjs.isDayjs(valB)) {
    return valA.isSame(valB, "year");
  }
};

const ApplyAudienceFilters = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [doNarrowAudienceMutation, { loading: narrowAudienceLoading }] =
    useMutation(NARROW_AUDIENCE);
  const [doCopyAndEditAudienceFiltersMutation, { loading: copyAndEditAudienceFiltersLoading }] =
    useMutation(COPY_AND_EDIT_AUDIENCE_FILTERS);
  const [doNarrowFromNationalMutation, { loading: narrowFromNationalLoading }] =
    useMutation(NARROW_FROM_NATIONAL);

  const { audienceId } = useParams();
  const { organization } = useGetActiveOrg();
  const { audiences } = organization;
  const currentAudiences = useGetCurrentAudiences();
  const selectedAudience = useGetAudienceFromParam();
  const DEFAULT_FILTERS = useGetDefaultFilters();
  const transformFiltersForBackend = useTransformFiltersForBackendFn();
  const { formErrors, updateFormError } = useFormErrors();

  const [audienceFilters, setAudienceFilters] = useState(DEFAULT_FILTERS);
  const [previewAudience, setPreviewAudience] = useState();

  const searchParams = new URLSearchParams(location.search);
  const startFromExisting = searchParams.get("startFromExisting") !== null;
  const isFilterFromNational = !startFromExisting && !audienceId;
  // This determines whether the action we're doing is "narrow an audience with filters"
  // (where the audience we're narrowing from is canNarrowFrom=true) or "copy and edit filters"
  // (where the audience we're copying and editing filters from is canCopyAndEditFiltersFrom=true).
  // Note that currently any given audience is either one or the other.
  const isNarrowing = selectedAudience && selectedAudience.canNarrowFrom;
  const isCopyingAndEditing = selectedAudience && selectedAudience.canCopyAndEditFiltersFrom;
  const baseAudience = useMemo(() => {
    if (!selectedAudience) {
      return;
    } else if (selectedAudience.audienceFilteredFrom) {
      return audiences.find(a => a.id === selectedAudience.audienceFilteredFrom.id);
    } else if (selectedAudience.canNarrowFrom) {
      return selectedAudience;
    } else {
      return null;
    }
  }, [selectedAudience, audiences]);
  const currentVersionOfBaseAudience = useMemo(() => {
    if (baseAudience && baseAudience.currentVersion) {
      return audiences.find(a => a.id === baseAudience.currentVersion.id);
    }
  }, [baseAudience, audiences]);
  const baseAudienceToUse = currentVersionOfBaseAudience || baseAudience;

  const anyMutationIsLoading =
    narrowAudienceLoading || copyAndEditAudienceFiltersLoading || narrowFromNationalLoading;

  let filtersToStartFrom;
  if (isCopyingAndEditing) {
    filtersToStartFrom = selectedAudience.filters;
  }

  const requireConfirmationBeforeLeaving = !!(previewAudience || anyMutationIsLoading);
  const isFormDisabled = anyMutationIsLoading;
  const isArchivedAudienceSelected = selectedAudience && selectedAudience.isArchived;
  const isFormHidden = (!isFilterFromNational && !selectedAudience) || isArchivedAudienceSelected;

  const transformedFiltersToStartFrom = useTransformedFiltersFromBackend(filtersToStartFrom);
  const previewFilters = previewAudience && previewAudience.filters;
  const transformedPreviewFilters = useTransformedFiltersFromBackend(previewFilters);
  const filtersAreSameAsStartingFilters = useMemo(
    () => transformedFiltersToStartFrom && isEqual(audienceFilters, transformedFiltersToStartFrom),
    [audienceFilters, transformedFiltersToStartFrom],
  );
  const filtersAreSameAsDefaultFilters = useMemo(
    () => isEqual(audienceFilters, DEFAULT_FILTERS),
    [audienceFilters, DEFAULT_FILTERS],
  );
  // We need to sort all the array values in the filters object to avoid the two objects not being
  // considered equal if the items in those arrays are not in the same order.
  const filtersAreSameAsPreviewFilters = useMemo(
    () =>
      transformedPreviewFilters &&
      isEqualWith(
        objectWithSortedListValues(audienceFilters),
        objectWithSortedListValues(transformedPreviewFilters),
        customCompare,
      ),
    [audienceFilters, transformedPreviewFilters],
  );
  const isCreatePreviewDisabled =
    isFormDisabled ||
    Object.keys(formErrors).length > 0 ||
    filtersAreSameAsPreviewFilters ||
    filtersAreSameAsStartingFilters ||
    filtersAreSameAsDefaultFilters;

  // If we're starting from an existing set of filters, set state to that.
  useEffect(() => {
    setAudienceFilters(transformedFiltersToStartFrom || DEFAULT_FILTERS);
  }, [transformedFiltersToStartFrom, DEFAULT_FILTERS, setAudienceFilters]);

  // If the user creates an empty preview audience, we revert the preview content on the right
  // to the null state as soon as they modify the filters.
  useEffect(() => {
    if (previewAudience && previewAudience.status !== "ACTIVE" && !filtersAreSameAsPreviewFilters) {
      setPreviewAudience(null);
    }
  }, [previewAudience, setPreviewAudience, filtersAreSameAsPreviewFilters]);

  // If user tries to leave the app entirely when we're creating a preview or
  // haven't promoted it yet, confirm.
  // @todo-later extract this section to a hook?
  const handleBeforeUnload = useCallback(
    event => {
      if (requireConfirmationBeforeLeaving) {
        event.preventDefault();
        event.returnValue = ""; // Required for Chrome
        return ""; // Required for other browsers
      }
    },
    [requireConfirmationBeforeLeaving],
  );
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
  // If a user tries to navigate to a different page in the app, do the same.
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      requireConfirmationBeforeLeaving &&
      currentLocation.pathname !== nextLocation.pathname &&
      // Don't block if we're navigating to the audience details page after promoting the audience.
      (!previewAudience || nextLocation.pathname !== `/audiences/${previewAudience.id}`),
  );
  useEffect(() => {
    if (blocker && blocker.state === "blocked") {
      const override = window.confirm(
        "Are you sure you want to discard the audience you're in the process of creating?",
      );
      if (override) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
    return () => {
      if (blocker && blocker.state === "blocked") {
        blocker.reset();
      }
    };
  }, [blocker]);

  const setSelectedAudienceAndStartFromExisting = (
    // Set the new selected audience
    newSelectedAudience,
    // Boolean: are we starting from an existing audience but that audience may not yet be defined.
    // If true, that will result in the "existing audience" radio option being selected even if no
    // audience has been selected yet.
    newStartFromExisting = startFromExisting,
  ) => {
    const selectedAudienceParam = newSelectedAudience ? `/${newSelectedAudience.id}` : "";
    const startFromExistingParam =
      newStartFromExisting && !newSelectedAudience ? "?startFromExisting" : "";
    if (!newSelectedAudience && previewAudience) {
      setPreviewAudience(null);
    }
    // Reset filters if we switch to "filter from national".
    if (!newSelectedAudience) {
      setAudienceFilters(DEFAULT_FILTERS);
    }
    navigate(`/audiences/filters${selectedAudienceParam}${startFromExistingParam}`);
  };

  const narrowAudience = newFilters => {
    doNarrowAudienceMutation({
      variables: {
        input: {
          pk: selectedAudience.id,
          filters: newFilters,
        },
      },
      onCompleted: data => {
        if (data.narrowAudience.id) {
          setPreviewAudience(data.narrowAudience);
        }
      },
    });
  };

  const narrowFromNational = newFilters => {
    doNarrowFromNationalMutation({
      variables: {
        input: {
          organizationId: organization.id,
          filters: newFilters,
        },
      },
      onCompleted: data => {
        if (data.narrowFromNational.id) {
          setPreviewAudience(data.narrowFromNational);
        }
      },
    });
  };

  const copyAndEditAudienceFilters = newFilters => {
    doCopyAndEditAudienceFiltersMutation({
      variables: {
        input: {
          pk: selectedAudience.id,
          filters: newFilters,
        },
      },
      onCompleted: data => {
        if (data.copyAndEditAudienceFilters.id) {
          setPreviewAudience(data.copyAndEditAudienceFilters);
        }
      },
    });
  };

  const createPreview = () => {
    const transformedFilters = transformFiltersForBackend(audienceFilters);
    if (isFilterFromNational) {
      narrowFromNational(transformedFilters);
    } else if (isNarrowing) {
      narrowAudience(transformedFilters);
    } else if (isCopyingAndEditing) {
      copyAndEditAudienceFilters(transformedFilters);
    }
  };

  let infoBoxContent;
  let lookalikeWithFiltersDisplay;
  let ifLookalikeDisplay = "";
  if (baseAudienceToUse && baseAudienceToUse.audienceLookalikeCreatedFrom) {
    ifLookalikeDisplay = "lookalike ";
    if (baseAudienceToUse.filters) {
      lookalikeWithFiltersDisplay = (
        <>
          {" "}
          Note that <FiltersSummaries audience={baseAudienceToUse} includeWasOrWere={true} />{" "}
          already applied to that lookalike when you created it.
        </>
      );
    }
  }
  if (isArchivedAudienceSelected) {
    infoBoxContent = (
      <>Cannot apply filters to an archived audience. Please select another audience.</>
    );
  } else if (isNarrowing) {
    infoBoxContent = (
      <>
        This audience is based on the{" "}
        <strong>
          {userFriendlyNumber(baseAudienceToUse.metadata.size)} people in the {ifLookalikeDisplay}
          audience <Link to={`/audiences/${baseAudienceToUse.id}`}>{baseAudienceToUse.name}</Link>.
        </strong>{" "}
        The filters below will help you create a new audience by further narrowing that list.
        {lookalikeWithFiltersDisplay}
      </>
    );
  } else if (isCopyingAndEditing && baseAudienceToUse) {
    const baseAudienceNotCurrentVersionCopy = currentVersionOfBaseAudience
      ? "the current version of "
      : "";
    infoBoxContent = (
      <>
        You are creating a new audience by filtering the{" "}
        <strong>
          {userFriendlyNumber(baseAudienceToUse.metadata.size)} people in{" "}
          {baseAudienceNotCurrentVersionCopy}the {ifLookalikeDisplay}
          audience <Link to={`/audiences/${baseAudienceToUse.id}`}>{baseAudienceToUse.name}</Link>
        </strong>
        , starting with the <strong>same filters used in your selected audience</strong>.
        {lookalikeWithFiltersDisplay}
      </>
    );
  } else if (isCopyingAndEditing) {
    infoBoxContent = (
      <>
        You are creating a new audience by filtering the U.S. voter file,{" "}
        <strong>starting with the same filters as your selected audience</strong>.
      </>
    );
  } else if (isFilterFromNational) {
    infoBoxContent = (
      <>
        You are filtering from the <strong>national voter file</strong>.
      </>
    );
  } else if (!isFilterFromNational && !selectedAudience) {
    if (currentAudiences.length === 0) {
      infoBoxContent = (
        <>
          You don't currently have any active audiences. To create your first audience, either{" "}
          <Link to="/audiences/filters">
            <strong>apply filters to national voter file</strong>
          </Link>{" "}
          or{" "}
          <Link to="/audiences/match">
            <strong>match a CSV to the voter file</strong>
          </Link>
        </>
      );
    } else {
      infoBoxContent = <>Select an existing audience to apply filters to.</>;
    }
  }

  let previewContent;
  if (previewAudience && !anyMutationIsLoading) {
    previewContent = (
      <>
        <PreviewAudience
          audience={previewAudience}
          handleResetFiltersToPreview={() => setAudienceFilters(transformedPreviewFilters)}
          handleUpdatePreview={isCreatePreviewDisabled ? null : createPreview}
          isStale={!filtersAreSameAsPreviewFilters}
        />
      </>
    );
  } else if (!isFormHidden) {
    previewContent = 'Define your filters and click the "Preview" button to see a preview.';
  }
  const previewContentOrLoading = (
    <LoadingExtended
      isLoading={anyMutationIsLoading}
      renderOnComplete={previewContent}
      steps={PREVIEW_LOADING_STEPS}
      title="Creating preview..."
    />
  );

  let createPreviewButtonContent;
  if (anyMutationIsLoading) {
    createPreviewButtonContent = "Loading...";
  } else if (previewAudience) {
    createPreviewButtonContent = "Update preview";
  } else {
    createPreviewButtonContent = "Preview";
  }
  let submitButton;
  if (!previewAudience || previewAudience.status !== "ACTIVE" || !filtersAreSameAsPreviewFilters) {
    submitButton = (
      <Button disabled={isCreatePreviewDisabled} onClick={createPreview} sx={{ width: 180 }}>
        {createPreviewButtonContent}
      </Button>
    );
  } else {
    submitButton = <PromoteAudience audience={previewAudience} buttonWidth={180} />;
  }

  const maxLimit = baseAudienceToUse ? baseAudienceToUse.metadata.size : null;

  let audienceFiltersDisplay, submitOrCancelDisplay;
  if (!isFormHidden) {
    audienceFiltersDisplay = (
      <AudienceFilters
        {...{ audienceFilters, setAudienceFilters, formErrors, updateFormError, maxLimit }}
        isDisabled={isFormDisabled}
      />
    );
    submitOrCancelDisplay = (
      <FixedToBottomGrid>
        <Grid item>{submitButton}</Grid>
        <Grid item>
          <Button
            component={Link}
            disabled={anyMutationIsLoading}
            to="/audiences"
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </FixedToBottomGrid>
    );
  }

  const mainContent = (
    <>
      <FormControl fullWidth>
        <TextLabel id="apply-filters-to">Start from:</TextLabel>
        <RadioGroup
          aria-labelledby="apply-filters-to"
          defaultValue="true"
          onChange={e => setSelectedAudienceAndStartFromExisting(null, e.target.value !== "true")}
          value={isFilterFromNational}
        >
          <FormControlLabel
            control={<Radio />}
            disabled={anyMutationIsLoading}
            label="National voter file"
            name="start-from-national-voter-file"
            value="true"
          />
          <FormControlLabel
            disableTypography
            control={<Radio />}
            disabled={anyMutationIsLoading}
            label={
              <Grid container alignItems="center" spacing={1}>
                <Grid item>Existing audience: </Grid>
                <Grid item xs>
                  <SelectAudience
                    isDisabled={
                      isFilterFromNational || anyMutationIsLoading || currentAudiences.length === 0
                    }
                    label={null}
                    placeholder={!isFilterFromNational ? "Select an audience" : null}
                    selectedAudience={isArchivedAudienceSelected ? null : selectedAudience}
                    setSelectedAudience={setSelectedAudienceAndStartFromExisting}
                  />
                </Grid>
              </Grid>
            }
            name="start-from-existing-audience"
            value="false"
          />
        </RadioGroup>
      </FormControl>

      <InfoBox backgroundColor="veryLightMagenta">{infoBoxContent}</InfoBox>
      <br />
      {audienceFiltersDisplay}
    </>
  );

  return (
    <>
      <TwoColumnLayoutWithPreview
        mainContent={mainContent}
        previewContent={previewContentOrLoading}
      />
      {submitOrCancelDisplay}
    </>
  );
};

export default ApplyAudienceFilters;
